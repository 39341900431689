export const VideoForPortfolio = () => {
  return (
    <>
      <section className="video-block">
        <div className="container">
          <div className="row mb-4 mb-lg-0">
            <div className="col-lg-8">
              <h2>
                <span className="lightgray">Посмотрите</span> как мы выполняем работу по заливке
              </h2>
            </div>
            <div className="d-none d-lg-block col-lg-2">
              <div className="ico-play">
                <img src="../images/play-yellow.png" alt="" />
              </div>
            </div>
            {/* <div className="col-lg-2">
              <a href="#" className="vertical-link">
                все видео
              </a>
            </div> */}
          </div>
        </div>

        <div className="video-block__wrap">
          <video src="/images/Вектор-М.mp4" muted controls poster="../images/poster.jpg"></video>
          <div className="container">
            <div className="ico-size d-none d-md-flex">
              <img src="../images/ico-size.png" alt="" />
            </div>
            <div className="row">
              <div className="offset-md-6 col-md-6 video-block__title">
                — Заливка пешеходного перехода / Видео #1
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

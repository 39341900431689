import { useState } from "react";
import { useModal } from "../../models/modalContext/ModalContext";
import { VideoModal } from "../UI/modal/videoModal/VideoModal";

export const Introduce = () => {
  const { setVideoIsOpen } = useModal();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="main-top">
      <div className="container">
        <div className="main-top__info">
          <div className="row">
            <div className="col-xl-6">
              <div className="main-top__subtitle">Разметка дорог любой сложности</div>
              <div className="main-top__title">Вектор-М</div>
            </div>
            <div className="col-xl-5 text-m">
              Нанесение дорожной разметки краской, термопластиком, холодным пластиком. Механические
              и ручные работы.
            </div>
          </div>
        </div>
      </div>

      <div
        className="main-top__ico"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.2s",
          backgroundColor: "#232123",
          border: "1px solid #FFEB00",
          //  isHovered ? "red" : "transparent",
          // filter: "invert(1)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setVideoIsOpen(true);
        }}
      >
        <img
          src={isHovered ? "/images/ico-play-yellow.png" : "/images/ico-play-normal.png"}
          alt=""
          style={{ width: "32px", height: "32px" }}
        />
        <p
          style={{
            position: "absolute",
            display: "block",
            top: "64px",
            width: "90px",
            left: "50%",
            transform: "translate(-50%, 0 )",
            fontSize: "10px",
            lineHeight: "14px",
            textAlign: "center",
          }}
        >
          Посмотреть видео о нас
        </p>
      </div>

      <VideoModal
        contentLabel="video"
        className="videoModal"
        overlayClassName="videoOverlay"
        videoSrc="/images/Вектор-М.mp4"
      />
    </div>
  );
};

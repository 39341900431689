import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/UI/loader/Loader";
import { getOneProject } from "../../models/projects/projects.selectors";
import { fetchOneProject } from "../../models/projects/projects.actions";
import "./projectPage.scss";
import { Colorbox } from "../../components/colorbox/Colorbox";

export const ProjectPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const data = useSelector(getOneProject);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const imgNew = document.querySelector(
      'img[src="../images/detail-img-2.jpg"]'
    );
    const imgNew2 = document.querySelector(
      'img[src="../images/img-decor.png"]'
    );
    const imgNew3 = document.querySelector(
      'img[src="../images/video-title.png"]'
    );
    const imgNew4 = document.querySelector(
      'img[src="../images/project_2/photo/0050.jpg"]'
    );

    if (imgNew) {
      const divNew = imgNew.closest("div.img");

      if (divNew) {
        imgNew.classList.add("fullScreenImg");
        divNew.classList.add("imageContainer2");
      }
    }
    if (imgNew2) {
      const divNew2 = imgNew2.closest("div.img");

      if (divNew2) {
        imgNew2.classList.add("fullScreenImg");
        divNew2.classList.add("imageContainer");
      }
    }
    if (imgNew3) {
      const divNew3 = imgNew3.closest("div.img");

      if (divNew3) {
        imgNew3.classList.add("fullScreenImg");
        divNew3.classList.add("imageContainer");
      }
    }
    if (imgNew4) {
      const divNew4 = imgNew4.closest("div.img");

      if (divNew4) {
        imgNew4.classList.add("fullScreenImg");
        divNew4.classList.add("imageContainer3");
      }
    }
  });

  useEffect(() => {
    if (slug) {
      setLoading(true);
      dispatch(fetchOneProject(slug));
      setLoading(false);
    }
  }, [dispatch, slug]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="main">
      {data ? <Colorbox /> : ""}
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: data?.body }}
      />
    </div>
  );
};
